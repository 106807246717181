<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <v-card class="mx-auto" max-width="344">
      <v-card-text>
        <h1>Subscribe Now</h1>
        <p class="text-h4 text--primary">
          You can subscribe now and get access for the quiz.
        </p>
        <p>Number of Access: 10</p>
        <div class="text--primary">
          Time to subscribe your offer now!
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn rounded depressed color="indigo" @click="subscribe" :loading="loader">
          Subscribe Now
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
 
<script>
import ApiService from "@/common/api.service"

export default {
  props: ['quiz'],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'Quiz',
          disabled: true,
          href: '/quiz-view/1',
        },
      ],
      loader: false,
    }
  },
  methods: {
    subscribe() {
      this.loader = true
      ApiService.setHeader()
      ApiService.get(`/pub/payment/send`).then(res => {
        this.loader = false;
        const flow_config = {
          merchantId: res.data.mercid,
          bdOrderId: res.data.bdorderid,
          authToken: res.data.links[1].headers.authorization,
          childWindow: process.env.VUE_APP_BILLDESK_CHILD_WINDOW == "true" ? true : false,
          returnUrl: process.env.VUE_APP_BILLDESK_RESPONSE_URL
        };	
        const config = {
          merchantLogo: "",
          flowConfig: flow_config,
          flowType: "payments"
        };
        //const xmlhttp = new XMLHttpRequest();
        //const jsonObj = "";
				window.loadBillDeskSdk(config);

      }).catch(err => {
          this.$toastr.e(err)
      })
    }
  },
  created() {
    this.$router.push({path:`/quiz-view/1`})
  }
}
</script>
